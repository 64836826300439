import React, { useEffect, useMemo, useState } from "react";
import {
  ClearAllOutlined,
  SettingsOutlined,
  Edit,
  AddOutlined,
} from "@mui/icons-material";
import { GripVertical } from "lucide-react";
import { Button, Checkbox } from "components";
import Popover from "components/Popover";
import {
  AddCustomFieldsModal,
  EditCustomFieldModal,
  useCustomFieldContext,
  useOrderTableCustomFieldsMutation,
  useUpdateCustomFieldMutation,
} from "features/custom-fields";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { isError } from "helpers/typeguards";
import { sortBy } from "lodash";
import { CustomFieldVisibility } from "generatedTypes";
import { dndReorder } from "helpers/dndReorder";
import PrebuiltUIModal from "./PrebuiltUIModal";

const CustomFieldsDropdown = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPrebuiltUiModal, setShowPrebuiltUiModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [selectedCustomField, setSelectedCustomField] = useState({
    id: "",
    label: "",
    key: "",
    hidden: false,
    visibility: CustomFieldVisibility.Private,
    restrictToAdmin: false,
  });

  const [updateCustomField] = useUpdateCustomFieldMutation();
  const [orderTableCustomFields] = useOrderTableCustomFieldsMutation();
  const { createToastAlert } = useToastAlertContext();

  const { customFields, refetchCustomFields } = useCustomFieldContext();
  const [items, setItems] = useState(customFields);
  const sortedCustomFields = useMemo(
    () => sortBy(customFields, "tableOrder"),
    [customFields]
  );

  useEffect(() => setItems(sortedCustomFields), [sortedCustomFields]);

  const handleShowEditModal = (selected) => () => {
    setSelectedCustomField(selected);
    setShowEditModal(true);
  };

  const handleOnOrder = async (fieldOrder) => {
    const orders = fieldOrder.map(({ id, label }, idx) => ({
      customFieldId: id,
      order: idx,
    }));
    createToastAlert({ processing: true });
    try {
      await orderTableCustomFields({
        variables: {
          input: {
            orders,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: "Order of the custom fields have been successfully updated.",
      });
    } catch (e) {
      if (isError(e)) {
        createToastAlert({
          alertType: "error",
          message: e.message,
        });
      }
    } finally {
      await refetchCustomFields();
    }
  };
  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedItems = dndReorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);

    handleOnOrder(reorderedItems);
  };
  const handleOnHide = async ({ tableHidden, label, id }) => {
    createToastAlert({ processing: true });
    try {
      await updateCustomField({
        variables: {
          input: {
            customFieldId: id,
            tableHidden: !tableHidden,
            label,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: `${label} field is now ${
          !tableHidden ? "hidden" : "unhidden"
        } on table.`,
      });
      refetchCustomFields();
    } catch (e) {
      if (isError(e)) {
        createToastAlert({
          alertType: "error",
          message: e.message,
        });
      }
    }
  };

  const moreOptions = [
    {
      text: "Add a custom field",
      icon: <AddOutlined style={{ fontSize: 16 }} />,
      onClick: () => setShowAddModal(true),
      dataCy: "add-custom-field-button",
    },
    {
      text: "Pre-built UI Settings",
      icon: <SettingsOutlined style={{ fontSize: 16 }} />,
      onClick: () => setShowPrebuiltUiModal(true),
      dataCy: "prebuilt-ui-settings-button",
    },
  ];

  const CustomFieldsOptions = useMemo(
    () => (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided: DroppableProvided) => (
            <ul
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              className="max-h-[500px] overflow-auto py-2"
            >
              {items.map(
                (
                  { id, tableHidden, label, key, hidden, visibility, restrictToAdmin },
                  index: number
                ) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(
                      draggableProvided: DraggableProvided,
                      snapshot: DraggableStateSnapshot
                    ) => (
                      <li
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        className="drag-indicator-container top-auto! left-auto! flex justify-between items-center px-3 py-2"
                        style={{
                          ...draggableProvided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? "0px 4px 24px rgba(0, 0, 0, 0.04)"
                            : "none",
                        }}
                      >
                        <Checkbox
                          type="visibility"
                          label={label}
                          checked={tableHidden}
                          onChange={() =>
                            handleOnHide({ tableHidden, label, id })
                          }
                        />

                        <div className="flex items-center gap-2">
                          <Button
                            buttonStyle="icon"
                            leftIcon={
                              <Edit
                                style={{ fontSize: 16 }}
                                className="edit-icon"
                              />
                            }
                            onClick={handleShowEditModal({
                              id,
                              label,
                              key,
                              hidden,
                              visibility,
                              restrictToAdmin
                            })}
                            dataCy={`edit-custom-field-button-${key}`}
                          />
                          <GripVertical className="w-4 h-4" />
                        </div>
                      </li>
                    )}
                  </Draggable>
                )
              )}
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    ),
    [items]
  );

  return (
    <>
      <Popover
        style={{ left: "-160px", width: "300px" }}
        buttonText="Custom Fields"
        buttonLeftIcon={<ClearAllOutlined />}
        dataCy="custom-fields-button"
      >
        {CustomFieldsOptions}
        <hr />
        <ul className="p-1 list-none">
          {moreOptions.map(({ text, icon, onClick, dataCy }) => (
            <li key={text}>
              <div
                className="p-2 hover:bg-app-gray50 cursor-pointer text-right rounded flex items-center gap-2 focus:bg-app-gray50"
                data-cy={dataCy}
                onClick={onClick}
                aria-label={text}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClick();
                  }
                }}
                tabIndex={0}
                role="button"
              >
                {icon}
                {text}
              </div>
            </li>
          ))}
        </ul>
        {/* <Button
          buttonStyle="icon"
          text="Add a custom field"
          leftIcon={<AddOutlined />}
          onClick={() => setShowAddModal(true)}
          tw="p-4 mb-0.5 justify-end"
          dataCy="add-custom-field-button"
        />
        <Button
          buttonStyle="icon"
          text="Pre-built UI Settings"
          leftIcon={<SettingsOutlined />}
          onClick={() => setShowPrebuiltUiModal(true)}
          tw="p-4 justify-end"
        /> */}
      </Popover>
      <AddCustomFieldsModal
        setShowModal={setShowAddModal}
        showModal={showAddModal}
      />
      <EditCustomFieldModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        id={selectedCustomField.id}
        label={selectedCustomField.label}
        uniqueKey={selectedCustomField.key}
        hidden={selectedCustomField.hidden}
        visibility={selectedCustomField.visibility}
        restrictToAdmin={selectedCustomField.restrictToAdmin}
      />
      <PrebuiltUIModal
        customFields={customFields}
        refetch={refetchCustomFields}
        setShowModal={setShowPrebuiltUiModal}
        showModal={showPrebuiltUiModal}
        showCreateModalFn={setShowAddModal}
      />
    </>
  );
};

export default CustomFieldsDropdown;
