import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { App, Exact } from "generatedTypes";
import React, { useEffect, useMemo, useState } from "react";
import { CurrentAppQuery, useCurrentAppQuery } from "features";
import { useParams } from "react-router-dom";
import { useUserContext } from "./UserContext";

interface DefaultContext {
  isSubscribed: boolean;
  refetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<CurrentAppQuery>>;
  appData: App;
  isLoading: boolean;
  canAccessLiveFeatures: boolean;
  error: ApolloError;
}

const defaultContext: DefaultContext = {
  isSubscribed: false,
  refetch: undefined,
  appData: undefined,
  isLoading: undefined,
  canAccessLiveFeatures: undefined,
  error: undefined,
};

const AppDataContext = React.createContext(defaultContext);

const useAppDataContext = () => {
  return React.useContext(AppDataContext);
};

const AppDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, loading, refetch, error, client } = useCurrentAppQuery();
  const { saveAppToStorage, refetch: refetchUser } = useUserContext();
  const { appId } = useParams();

  if (data?.currentApp) {
    try {
      window.revgems("account", data.currentApp.id, {
        name: data.currentApp.name,
        stripe_customer_id: data.currentApp.stripeCustomerId,
      });
    } catch (err) {
      console.log(err);
    }
  }

  const isSubscribed = Boolean(data?.currentApp?.stripeSubscriptionId);

  const canAccessLiveFeatures = useMemo(
    () => Boolean(isSubscribed),
    [isSubscribed]
  );

  useEffect(() => {
    if (!data?.currentApp?.id) return;
    // @ts-ignore
    if (window.churnkey) {
      // @ts-ignore
      console.log("Checking Churnkey for failed payment wall");

      let ignoreCheck = false;

      const checkFailedPayment = () => {
        const handleFailedPaymentWallActivation = () => {
          console.log("Failed payment wall activated");

          const checkWallPresence = () => {
            if (ignoreCheck) return;
            const wallElement = document.getElementById(
              "ck-failed-payment-wall"
            );
            if (!wallElement) window.location.reload();
          };

          // Recheck every 2 seconds to ensure failed payment wall exists
          setInterval(checkWallPresence, 2000);
        };

        // @ts-ignore
        window.churnkey.check("failed-payment", {
          subscriptionId: data.currentApp.stripeSubscriptionId,
          customerId: data.currentApp.stripeCustomerId,
          authHash: data.currentApp.churnkeyAuthToken,
          appId: "zizprvgt8", // required
          mode: process.env.ENVIRONMENT === "development" ? "test" : "live",
          provider: "stripe",
          softWall: false, // recommended to leave this to true to avoid hard wall
          forceCheck: false, // recommended to leave this to false to avoid redundant checks
          ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge
          onFailedPaymentWallActivated: handleFailedPaymentWallActivation,
          onUpdatePaymentInformation: () => {
            console.log("Update payment information");
            ignoreCheck = true;
          },
          onFailedPaymentWallClose: () => {
            console.log("Failed payment wall closed");
            ignoreCheck = true;
          },
        });
      };

      try {
        checkFailedPayment();
      } catch (err) {
        console.error("Error checking Churnkey for failed payment wall:", err);
      }
    }
  }, [data?.currentApp?.id]);

  useEffect(() => {
    if (data?.currentApp?.id && appId && data?.currentApp?.id !== appId) {
      saveAppToStorage(appId);
      client.resetStore().then(async () => {
        await refetchUser();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  return (
    <AppDataContext.Provider
      value={{
        appData: data?.currentApp as App,
        isLoading: loading,
        refetch,
        isSubscribed,
        canAccessLiveFeatures,
        error,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export { useAppDataContext, AppDataContextProvider };
