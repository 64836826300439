import React, { useEffect, useMemo, useRef } from "react";
import { Plugin, ScriptableContext } from "chart.js";
import { AnyObject } from "chart.js/dist/types/basic";
import { Line } from "react-chartjs-2";
import { StatChange } from "components/MetricsCard/StatChange";
import { MemberMetricsInterval } from "generatedTypes";
import { convertDate } from "./line-chart.utils";
import { ChartContainer } from "./line-chart.style";

interface Props {
  title: string;
  className?: string;
  plugins?: Plugin<"line", AnyObject>[];
  currentData: Record<string, number>;
  previousData: Record<string, number>;
  interval?: MemberMetricsInterval;
}

const getFirstAndLast = (arr) =>
  arr.map((_, i) => (i === 0 || i === arr.length - 1 ? arr[i] : ""));

export function LineChart({
  // data,
  className,
  plugins,
  title,
  currentData,
  previousData,
  interval,
}: Props) {
  const chartRef = useRef(null);

  const [currentPeriodValue, setCurrentPeriodValue] = React.useState(0);
  const [currentPeriodDate, setCurrentPeriodDate] = React.useState("-");
  const [previousPeriodValue, setPreviousPeriodValue] = React.useState(0);
  const [previousPeriodDate, setPreviousPeriodDate] = React.useState("-");

  const datasets = useMemo(
    () => [
      {
        label: "Current Period",
        backgroundColor: (context: ScriptableContext<"line">) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(41, 98, 255, 0.1)");
          gradient.addColorStop(1, "rgba(41, 98, 255, 0)");
          return gradient;
        },
        borderColor: "#2962FF",
        data: Object.values(currentData),
        fill: "start",
        borderWidth: 1.5,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: "#2962FF",
        currentPeriodDates: Object.keys(currentData).map((dateString) =>
          convertDate(dateString, interval)
        ),
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
      },
      {
        label: "Previous Period",
        backgroundColor: "#C9C9C9",
        borderColor: "#C9C9C9",
        data: Object.values(previousData),
        borderWidth: 1.5,
        pointRadius: 0,
        tension: 0.4,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: "#C9C9C9",
        prevPeriodDates: Object.keys(previousData).map((dateString) =>
          convertDate(dateString, interval)
        ),
      },
    ],
    [currentData, interval, previousData]
  );

  useEffect(() => {
    const chart = chartRef.current;
    chart.data.datasets = datasets;
    chart.update();
  }, [datasets]);

  const difference = useMemo(() => {
    if (previousPeriodValue === 0) {
      return currentPeriodValue === 0 ? 0 : 100;
    }

    const percentChange = (
      ((currentPeriodValue - previousPeriodValue) / previousPeriodValue) *
      100
    ).toFixed(2);
    return previousPeriodValue === 0 ? -100 : Number(percentChange);
  }, [currentPeriodValue, previousPeriodValue]);

  const setDataValues = (index) => {
    setCurrentPeriodValue(datasets[0].data[index]);
    setCurrentPeriodDate(datasets[0].currentPeriodDates[index]);
    setPreviousPeriodValue(datasets[1].data[index]);
    setPreviousPeriodDate(datasets[1].prevPeriodDates[index]);
  };

  const resetDisplayData = () => {
    setCurrentPeriodValue(datasets[0].data.reduce((a, b) => a + b, 0));
    setCurrentPeriodDate("-");
    setPreviousPeriodValue(datasets[1].data.reduce((a, b) => a + b, 0));
    setPreviousPeriodDate("-");
  };

  return (
    <ChartContainer className={className}>
      <div className="top_section items-center">
        <h4>{title}</h4>
        <span className="text-utility-tag-md flex text-app-gray500">
          <StatChange percentChange={difference} className="mr-1" /> vs previous
          period
        </span>
      </div>
      <div className="top_section" tw="items-baseline">
        <h1 className=" font-bold">{currentPeriodValue}</h1>
        <h3 className="text-h4 font-bold text-app-gray500">
          {previousPeriodValue}
        </h3>
      </div>
      <div className="top_section dates">
        <h4>{currentPeriodDate}</h4>
        <h4>{previousPeriodDate}</h4>
      </div>

      <div>
        <Line
          ref={chartRef}
          plugins={[
            {
              id: "hoverValue",
              afterDatasetsDraw(chart, pluginOptions) {
                const activeEl = chart.getActiveElements();
                if (activeEl.length !== 0) {
                  setDataValues(activeEl[0].index);
                } else resetDisplayData();
              },
            },
          ]}
          data={{
            labels: datasets[0].currentPeriodDates,
            datasets,
          }}
          options={{
            clip: false,
            layout: {
              padding: {
                left: 5,
                right: 5,
                bottom: 5,
                top: 5,
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                display: false,
              },
              x: {
                labels: getFirstAndLast(datasets[0].currentPeriodDates),
                grid: {
                  display: false,
                },
                ticks: {
                  align: "inner",
                  color: "#A4A4A4",
                  maxRotation: 0,
                  minRotation: 0,
                  font: {
                    size: 14,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxHeight: 1,
                },
              },
              tooltip: {
                enabled: false,
                backgroundColor: "white",
                borderColor: "#DDDDDD",
                borderWidth: 1,
                bodyColor: "black",
                titleColor: "black",
                intersect: false,
              },
            },
          }}
        />
      </div>
    </ChartContainer>
  );
}
