import { gql } from "@apollo/client";

export const GET_CUSTOM_FIELDS = gql`
  query GetCustomFields {
    getCustomFields {
      id
      key
      label
      hidden
      order
      tableHidden
      tableOrder
      visibility
      restrictToAdmin
    }
  }
`;
