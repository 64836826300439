import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCustomFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomFieldsQuery = (
  { __typename?: 'Query' }
  & { getCustomFields?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'CustomField' }
    & Pick<Types.CustomField, 'id' | 'key' | 'label' | 'hidden' | 'order' | 'tableHidden' | 'tableOrder' | 'visibility' | 'restrictToAdmin'>
  )>>> }
);


export const GetCustomFieldsDocument = gql`
    query GetCustomFields {
  getCustomFields {
    id
    key
    label
    hidden
    order
    tableHidden
    tableOrder
    visibility
    restrictToAdmin
  }
}
    `;

/**
 * __useGetCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>) {
        return Apollo.useQuery<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>(GetCustomFieldsDocument, baseOptions);
      }
export function useGetCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>(GetCustomFieldsDocument, baseOptions);
        }
export type GetCustomFieldsQueryHookResult = ReturnType<typeof useGetCustomFieldsQuery>;
export type GetCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetCustomFieldsLazyQuery>;
export type GetCustomFieldsQueryResult = Apollo.QueryResult<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>;