import React, { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useApolloClient,
} from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageKeys } from "helpers/cacheIds";
import {
  CurrentUserQuery,
  useCurrentUserQuery,
  useLogoutUserMutation,
} from "features";

interface DefaultContext {
  currentUser: CurrentUserQuery["currentUser"];
  error: ApolloError;
  isLoading: boolean;
  refetch: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<unknown>>;
  logout: () => Promise<void>;
  currentAppId: undefined | string;
  saveAppToStorage: (appId: string) => void;
}

const defaultContext: DefaultContext = {
  isLoading: false,
  error: undefined,
  currentUser: undefined,
  refetch: undefined,
  logout: undefined,
  currentAppId: undefined,
  saveAppToStorage: (appId) => {},
};

const UsersContext = React.createContext(defaultContext);

const useUserContext = () => {
  return React.useContext(UsersContext);
};

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, loading, error, refetch } = useCurrentUserQuery();
  const [logoutUserMutation] = useLogoutUserMutation();
  const client = useApolloClient();
  const location = useLocation();
  const posthog = usePostHog();
  const navigate = useNavigate();

  let currentAppId;
  if (data?.currentUser?.id) {
    const userData = JSON.parse(localStorage.getItem(data?.currentUser.id));
    const [matchingApp] = data.currentUser.appConnections.filter(
      (app) => app.app.id === userData?.lastAppId
    );
    if (userData?.lastAppId && matchingApp) {
      currentAppId = userData.lastAppId;
    } else {
      const [firstApp] = data.currentUser.appConnections;
      currentAppId = firstApp?.app.id;
    }
  }

  // Posthog script
  useEffect(() => {
    const page = location.pathname.split("/")[3];
    if (data?.currentUser) {
      posthog?.capture("pageview", { page });
      const { currentUser } = data;
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(currentUser.id, {
        email: currentUser.auth.email,
        name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
        connectedApps: currentUser.appConnections.length,
        appsOwned: currentUser.appConnections.filter(
          (app) => app.role === "OWNER"
        ).length,
        hasWebflowApp: currentUser.appConnections.some(
          (app) => app.app.stack === "WEBFLOW"
        ),
        hasReactApp: currentUser.appConnections.some(
          (app) => app.app.stack === "REACT"
        ),
        hasVanillaApp: currentUser.appConnections.some(
          (app) => app.app.stack === "VANILLA"
        ),
        paidApps: currentUser.appConnections.filter(
          (app) => app.app.stripePriceId
        ).length,
        basicApps: currentUser.appConnections.filter(
          (app) => app.app.stripeAppFeePercent === 4
        ).length,
        professionalApps: currentUser.appConnections.filter(
          (app) => app.app.stripeAppFeePercent === 2
        ).length,
        enterpriseApps: currentUser.appConnections.filter(
          (app) =>
            app.app.stripeAppFeePercent && app.app.stripeAppFeePercent < 2
        ).length,
      });

      try {
        window.revgems("user", currentUser.id, {
          name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
          email: currentUser.auth.email,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [data, posthog, location]);

  useEffect(() => {
    /* TODO: remove this after 2023-01-01 */
    const isUserLoggedAvailable = data?.currentUser ? "true" : "";

    if (!isUserLoggedAvailable) {
      window.localStorage.removeItem(localStorageKeys.appId);
      window.localStorage.removeItem(localStorageKeys.planId);
      window.localStorage.removeItem(localStorageKeys.memberId);
    }
  }, [data]);

  const saveAppToStorage = (appId: string) => {
    if (data?.currentUser?.id) {
      localStorage.setItem(
        data.currentUser?.id,
        JSON.stringify({ lastAppId: appId })
      );
    }
  };

  const logout = async () => {
    await logoutUserMutation();
    client.resetStore();
    window.localStorage.removeItem(localStorageKeys.appId);
    await refetch();
    navigate("/login");
  };

  return (
    <UsersContext.Provider
      value={{
        error,
        isLoading: loading,
        currentUser: data?.currentUser,
        currentAppId,
        saveAppToStorage,
        refetch,
        logout,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { useUserContext, UserContextProvider };
