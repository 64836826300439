import React, { useState, useContext, createContext } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
} from "@apollo/client";
import { MemberstackEnv } from "generatedTypes";
import { useGetMembersQuery } from "../../../features/members";

interface DefaultContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  members: any;
  endCursor: string;
  loadingMembers: boolean;
  membersError: ApolloError;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<Record<string, unknown>> &
      FetchMoreOptions<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<void>>;
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  mode: MemberstackEnv;
  showCreateMemberModal: boolean;
  setShowCreateMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultMembersContext: DefaultContext = {
  members: [],
  fetchMore: () => undefined,
  refetch: () => undefined,
  endCursor: undefined,
  loadingMembers: false,
  membersError: undefined,
  mode: undefined,
  showCreateMemberModal: false,
  setShowCreateMemberModal: () => { },
};

const MembersContext = createContext(defaultMembersContext);

const useMembersContext = () => useContext(MembersContext);

const MembersContextProvider = ({ children }) => {
  const [showCreateMemberModal, setShowCreateMemberModal] = useState(false);
  const {
    loading: loadingMembers,
    data,
    error,
    fetchMore,
    refetch,
  } = useGetMembersQuery({
    variables: {
      first: 25,
    },
  });

  const queryMembers = data?.getMembers;
  const mode = queryMembers?.mode;
  const { endCursor } = queryMembers?.pageInfo || {};
  const members = queryMembers?.edges.map(({ node }) => node);

  return (
    <MembersContext.Provider
      value={{
        members,
        refetch,
        fetchMore,
        endCursor,
        membersError: error,
        loadingMembers,
        showCreateMemberModal,
        setShowCreateMemberModal,
        mode,
      }}
    >
      {children}
    </MembersContext.Provider>
  );
};

export { useMembersContext, MembersContextProvider };
