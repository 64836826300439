export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  APIVersion: any;
  Date: any;
  EmailAddress: any;
  Event: any;
  JSON: any;
  MemberPurchase: any;
  TimeStamp: any;
  UIVersion: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApiKeys = {
  __typename?: 'APIKeys';
  publicKey?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
};


export type AccessLog = {
  __typename?: 'AccessLog';
  timestamp: Scalars['Date'];
  ip: Scalars['String'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type AddDomainToAppInput = {
  domain: Scalars['String'];
  mode: DomainMode;
};

export type AddEmailDomainInput = {
  email: Scalars['String'];
};

export type AddFreePlanInput = {
  planId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type AddUserToAppInput = {
  email: Scalars['String'];
  role?: Maybe<UserAppRole>;
};

export type App = Node & {
  __typename?: 'App';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Maybe<Domain>>>;
  status?: Maybe<AppStatus>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  permission: Permission;
  permissionGroup: PermissionGroup;
  permissionGroups?: Maybe<Array<Maybe<PermissionGroup>>>;
  user?: Maybe<User>;
  stripeConnection?: Maybe<AppStripeConnection>;
  config?: Maybe<AppConfig>;
  apiVersion: Scalars['APIVersion'];
  uiVersion: Scalars['UIVersion'];
  apiKeys?: Maybe<ApiKeys>;
  branding: Branding;
  captchaEnabled?: Maybe<Scalars['Boolean']>;
  businessEntityName?: Maybe<Scalars['String']>;
  termsOfServiceURL?: Maybe<Scalars['String']>;
  privacyPolicyURL?: Maybe<Scalars['String']>;
  ssoSignupButtonURL?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionStatus?: Maybe<Scalars['String']>;
  stripeAppFeePercent?: Maybe<Scalars['Float']>;
  lastStripeAppFeeChange?: Maybe<Scalars['Date']>;
  stripeIdealEnabled?: Maybe<Scalars['Boolean']>;
  stripeTaxIdCollectionEnabled?: Maybe<Scalars['Boolean']>;
  stripeBillingAddressCollectionEnabled?: Maybe<Scalars['Boolean']>;
  stripePortalCancelImmediately?: Maybe<Scalars['Boolean']>;
  stripeUseCustomerPortalSettings?: Maybe<Scalars['Boolean']>;
  stripeDisablePaymentModeInvoices?: Maybe<Scalars['Boolean']>;
  stripePortalDisableCancel?: Maybe<Scalars['Boolean']>;
  sendWelcomeEmailAfterVerification?: Maybe<Scalars['Boolean']>;
  memberCountLimit?: Maybe<Scalars['Int']>;
  testModeMemberLimitEnabled?: Maybe<Scalars['Boolean']>;
  hasCommentingPlan?: Maybe<Scalars['Boolean']>;
  weeklyRoundupEmailEnabled?: Maybe<Scalars['Boolean']>;
  dailyCommentingEmailEnabled?: Maybe<Scalars['Boolean']>;
  memberSessionDurationDays?: Maybe<Scalars['Int']>;
  passwordlessEnabled?: Maybe<Scalars['Boolean']>;
  defaultToPasswordless?: Maybe<Scalars['Boolean']>;
  preventDisposableEmails?: Maybe<Scalars['Boolean']>;
  clientMessageTextEnabled?: Maybe<Scalars['Boolean']>;
  disableConcurrentLogins?: Maybe<Scalars['Boolean']>;
  requireUser2FA?: Maybe<Scalars['Boolean']>;
  redirects?: Maybe<Redirects>;
  stack?: Maybe<Stack>;
  webflowSiteId?: Maybe<Scalars['String']>;
  webflowSiteName?: Maybe<Scalars['String']>;
  customDomain: CustomDomain;
  socketAuthToken: Scalars['String'];
  churnkeyAuthToken?: Maybe<Scalars['String']>;
};


export type AppPlanArgs = {
  id: Scalars['ID'];
};


export type AppPermissionArgs = {
  id: Scalars['ID'];
};


export type AppPermissionGroupArgs = {
  id: Scalars['ID'];
};


export type AppUserArgs = {
  id: Scalars['ID'];
};

export type AppConfig = {
  __typename?: 'AppConfig';
  rewardfulId?: Maybe<Scalars['String']>;
};

export type AppEvent = {
  __typename?: 'AppEvent';
  timestamp: Scalars['Date'];
  ip: Scalars['String'];
  user?: Maybe<User>;
  type: AppEventType;
  userId: Scalars['ID'];
};

export enum AppEventType {
  AppDeleted = 'APP_DELETED',
  StripeConnected = 'STRIPE_CONNECTED',
  StripeDisconnected = 'STRIPE_DISCONNECTED',
  AdminAdded = 'ADMIN_ADDED',
  AdminRemoved = 'ADMIN_REMOVED',
  OwnerChanged = 'OWNER_CHANGED',
  LiveMembersDeleted = 'LIVE_MEMBERS_DELETED',
  TestMembersDeleted = 'TEST_MEMBERS_DELETED',
  CustomDomainUpdated = 'CUSTOM_DOMAIN_UPDATED'
}

export enum AppStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type AppStripeConnection = {
  __typename?: 'AppStripeConnection';
  accountId: Scalars['String'];
};

export type AppUserConnection = {
  __typename?: 'AppUserConnection';
  user: User;
  role: UserAppRole;
};

export enum Appearance {
  Light = 'LIGHT',
  Dark = 'DARK',
  System = 'SYSTEM'
}

export enum AuthProvider {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Spotify = 'SPOTIFY',
  Intuit = 'INTUIT',
  Auth0 = 'AUTH0',
  Microsoft = 'MICROSOFT',
  Apple = 'APPLE',
  Okta = 'OKTA',
  Twitter = 'TWITTER',
  Cognito = 'COGNITO',
  Discourse = 'DISCOURSE',
  Discord = 'DISCORD',
  Xero = 'XERO',
  Dribbble = 'DRIBBBLE',
  Firebase = 'FIREBASE',
  Custom = 'CUSTOM'
}

export type AuthProviderConfig = {
  __typename?: 'AuthProviderConfig';
  id?: Maybe<Scalars['ID']>;
  providerType?: Maybe<AuthProviderType>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  discoveryUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  issuer?: Maybe<Scalars['String']>;
  authorizationUrl?: Maybe<Scalars['String']>;
  tokenUrl?: Maybe<Scalars['String']>;
  userInfoUrl?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export enum AuthProviderType {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Spotify = 'SPOTIFY',
  Intuit = 'INTUIT',
  Auth0 = 'AUTH0',
  Microsoft = 'MICROSOFT',
  Apple = 'APPLE',
  Okta = 'OKTA',
  Twitter = 'TWITTER',
  Cognito = 'COGNITO',
  Discourse = 'DISCOURSE',
  Discord = 'DISCORD',
  Xero = 'XERO',
  Dribbble = 'DRIBBBLE',
  Firebase = 'FIREBASE',
  Custom = 'CUSTOM'
}

export type AuthenticateUserWith2FaInput = {
  code: Scalars['String'];
};

export enum Borders {
  None = 'NONE',
  Thick = 'THICK',
  Thin = 'THIN'
}

export type Branding = Node & {
  __typename?: 'Branding';
  id: Scalars['ID'];
  appId: Scalars['ID'];
  appearance?: Maybe<Appearance>;
  colors: Colors;
  text?: Maybe<BrandingText>;
  logo?: Maybe<Scalars['String']>;
};

export type BrandingColors = {
  __typename?: 'BrandingColors';
  primaryButton: Scalars['String'];
  secondaryButton: Scalars['String'];
  background: Scalars['String'];
  errorBackground: Scalars['String'];
  text: Scalars['String'];
  primaryButtonText: Scalars['String'];
  titles: Scalars['String'];
  subtitles: Scalars['String'];
  links: Scalars['String'];
  borderColor: Scalars['String'];
  shadowColor: Scalars['String'];
};

export type BrandingText = {
  __typename?: 'BrandingText';
  titleLogin: Scalars['String'];
  subtitleLogin: Scalars['String'];
  subtitleSignupFree: Scalars['String'];
  subtitleSignupPaid: Scalars['String'];
  buttonLogin: Scalars['String'];
  buttonSignup: Scalars['String'];
};

export type ButtonComponentStyles = {
  __typename?: 'ButtonComponentStyles';
  corners: Corners;
  borders: Borders;
  shadows: Shadows;
};

export type CancelPlanInput = {
  planConnectionId: Scalars['ID'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  activityCount: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  realtimeEnabled: Scalars['Boolean'];
};

export type ClientMessageText = {
  __typename?: 'ClientMessageText';
  textObject?: Maybe<Scalars['JSON']>;
};

export type Colors = {
  __typename?: 'Colors';
  light: BrandingColors;
  dark: BrandingColors;
  appearance: Appearance;
};

export type ConnectAuthProviderInput = {
  code: Scalars['String'];
  provider: AuthProvider;
};

export type ConnectStripeAppInput = {
  code: Scalars['String'];
  appId: Scalars['ID'];
};

export enum Corners {
  Sharp = 'SHARP',
  Soft = 'SOFT',
  Round = 'ROUND'
}

export type CreateAppInput = {
  name: Scalars['String'];
  stack: Stack;
  domains?: Maybe<Array<Maybe<CreateDomainInput>>>;
  templateId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

export type CreateAppPayload = {
  __typename?: 'CreateAppPayload';
  id: Scalars['ID'];
  name: Scalars['String'];
  domains?: Maybe<Array<Maybe<Domain>>>;
  status: AppStatus;
  createdAt: Scalars['String'];
};

export type CreateBillingPortalSessionInput = {
  appId?: Maybe<Scalars['ID']>;
};

export type CreateChannelInput = {
  name: Scalars['String'];
};

export type CreateCheckoutSessionInput = {
  priceId: Scalars['String'];
  rewardfulId?: Maybe<Scalars['String']>;
};

export type CreateCustomContentExistingGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  content: Scalars['String'];
  name: Scalars['String'];
  type: CustomContentType;
};

export type CreateCustomContentNewGroupInput = {
  content: Scalars['String'];
  name: Scalars['String'];
  type: CustomContentType;
};

export type CreateCustomFieldInput = {
  key: Scalars['String'];
  label: Scalars['String'];
  planIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hidden?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<CustomFieldVisibility>;
};

export type CreateDomainInput = {
  url: Scalars['String'];
  verified: Scalars['Boolean'];
  mode: DomainMode;
};

export type CreatePermissionGroupInput = {
  name: Scalars['String'];
  permissionIds: Array<Scalars['ID']>;
};

export type CreatePermissionInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreatePermissionPayload = {
  __typename?: 'CreatePermissionPayload';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreatePlanConnectionInput = {
  planId: Scalars['ID'];
};

export type CreatePlanInput = {
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  teamAccountsEnabled?: Maybe<Scalars['Boolean']>;
  teamAccountInviteSignupLink?: Maybe<Scalars['String']>;
  teamAccountUpgradeLink?: Maybe<Scalars['String']>;
};

export type CreatePriceInput = {
  planId: Scalars['ID'];
  amount: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  type: PriceType;
  description?: Maybe<Scalars['String']>;
  currency: PriceCurrency;
  intervalType?: Maybe<PriceIntervalType>;
  intervalCount?: Maybe<Scalars['Int']>;
  setupFeeAmount?: Maybe<Scalars['Float']>;
  setupFeeName?: Maybe<Scalars['String']>;
  setupFeeEnabled?: Maybe<Scalars['Boolean']>;
  freeTrialEnabled?: Maybe<Scalars['Boolean']>;
  freeTrialRequiresCard?: Maybe<Scalars['Boolean']>;
  freeTrialDays?: Maybe<Scalars['Int']>;
  expirationCount?: Maybe<Scalars['Int']>;
  expirationInterval?: Maybe<PriceExpirationInterval>;
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  maxTeamMembers?: Maybe<Scalars['Int']>;
  teamAccountsEnabled?: Maybe<Scalars['Boolean']>;
};

export type CreateRestrictedUrlGroupInput = {
  name: Scalars['String'];
  redirect: Scalars['String'];
  planIds: Array<Scalars['ID']>;
  urls: Array<CreateRestrictedUrlNoGroupIdInput>;
  allowAllMembers: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  customContent: Array<CreateCustomContentNewGroupInput>;
};

export type CreateRestrictedUrlInput = {
  url: Scalars['String'];
  filter?: Maybe<FilterType>;
  restrictedUrlGroupId: Scalars['ID'];
};

export type CreateRestrictedUrlNoGroupIdInput = {
  url: Scalars['String'];
  filter?: Maybe<FilterType>;
};

export type CreateSsoAppInput = {
  name: Scalars['String'];
  redirectUris: Array<Scalars['String']>;
};

export type CreateSsoClientInput = {
  providerType: AuthProviderType;
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discoveryUrl?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  issuer?: Maybe<Scalars['String']>;
  authorizationUrl?: Maybe<Scalars['String']>;
  tokenUrl?: Maybe<Scalars['String']>;
  userInfoUrl?: Maybe<Scalars['String']>;
};

export type CreateStripeCustomerInput = {
  memberId?: Maybe<Scalars['ID']>;
};

export type CreateWebhookInput = {
  endpoint: Scalars['String'];
  events: Array<Scalars['Event']>;
  apiVersion?: Maybe<Scalars['APIVersion']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomContent = {
  __typename?: 'CustomContent';
  id: Scalars['ID'];
  restrictedUrlGroupId: Scalars['ID'];
  content: Scalars['String'];
  name: Scalars['String'];
  key: Scalars['String'];
  type: CustomContentType;
};

export enum CustomContentType {
  Html = 'HTML',
  Css = 'CSS',
  Link = 'LINK',
  Js = 'JS',
  Json = 'JSON',
  Text = 'TEXT',
  Image = 'IMAGE',
  File = 'FILE'
}

export type CustomDomain = {
  __typename?: 'CustomDomain';
  rootDomain?: Maybe<Scalars['String']>;
  authSubdomain?: Maybe<Scalars['String']>;
  clientSubdomain?: Maybe<Scalars['String']>;
  staticSubdomain?: Maybe<Scalars['String']>;
};

export type CustomField = Node & {
  __typename?: 'CustomField';
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['String'];
  hidden: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  tableHidden: Scalars['Boolean'];
  tableOrder?: Maybe<Scalars['Int']>;
  visibility: CustomFieldVisibility;
};

export type CustomFieldOrderInput = {
  customFieldId: Scalars['ID'];
  order: Scalars['Int'];
};

export enum CustomFieldVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type DeactivatePriceInput = {
  priceId: Scalars['ID'];
};

export type DeleteAppInput = {
  appId: Scalars['ID'];
};

export type DeleteAppPayload = {
  __typename?: 'DeleteAppPayload';
  id: Scalars['ID'];
  name: Scalars['String'];
  domains?: Maybe<Array<Maybe<Domain>>>;
  status?: Maybe<AppStatus>;
  createdAt: Scalars['String'];
  deletedAt: Scalars['String'];
};

export type DeleteCustomContentInput = {
  customContentId: Scalars['ID'];
};

export type DeleteCustomFieldInput = {
  customFieldId: Scalars['ID'];
};

export type DeleteMemberInput = {
  memberId: Scalars['ID'];
};

export type DeletePermissionGroupInput = {
  permissionGroupId: Scalars['ID'];
};

export type DeletePermissionInput = {
  permissionId: Scalars['ID'];
};

export type DeletePlanInput = {
  planId: Scalars['ID'];
};

export type DeletePostInput = {
  postId: Scalars['ID'];
};

export type DeletePriceInput = {
  priceId: Scalars['ID'];
};

export type DeleteRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
};

export type DeleteRestrictedUrlInput = {
  urlId: Scalars['ID'];
};

export type DeleteSsoAppInput = {
  id: Scalars['ID'];
};

export type DeleteWebhookInput = {
  id: Scalars['ID'];
};

export type DetachPermissionFromMemberInput = {
  permissionId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type DetachPermissionFromPlanInput = {
  permissionId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type DetachPermissionGroupFromMemberInput = {
  permissionGroupIds: Array<Scalars['ID']>;
  memberId: Scalars['ID'];
};

export type DetachPermissionGroupFromPlanInput = {
  permissionGroupIds: Array<Scalars['ID']>;
  planId: Scalars['ID'];
};

export type DetachPermissionsFromGroupInput = {
  permissionIds: Array<Scalars['ID']>;
  permissionGroupId: Scalars['ID'];
};

export type DetachPlansFromRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  planIds: Array<Scalars['ID']>;
};

export type DetachRestrictedUrlsFromRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  urlIds: Array<Scalars['ID']>;
};

export type DisconnectAuthProviderInput = {
  provider: AuthProvider;
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['ID'];
  url: Scalars['String'];
  verified: Scalars['Boolean'];
  mode: DomainMode;
};

export enum DomainMode {
  Live = 'LIVE',
  Sandbox = 'SANDBOX'
}


export type Emails = {
  __typename?: 'Emails';
  verified: Scalars['Boolean'];
  from?: Maybe<Scalars['String']>;
  passwordReset?: Maybe<PasswordResetTemplate>;
  emailVerification?: Maybe<EmailVerificationTemplate>;
  passwordlessLogin?: Maybe<PasswordlessLoginTemplate>;
  commentNotification?: Maybe<CommentNotificationTemplate>;
  welcome?: Maybe<WelcomeTemplate>;
  abandonedCart?: Maybe<AbandonedCartTemplate>;
  emailVerificationEnabled?: Maybe<Scalars['Boolean']>;
  requireEmailVerification?: Maybe<Scalars['Boolean']>;
  welcomeEmailsEnabled?: Maybe<Scalars['Boolean']>;
  abandonedCartEmailsEnabled?: Maybe<Scalars['Boolean']>;
  abandonedCartExpireMinutes?: Maybe<Scalars['Int']>;
  emailDNSSettings?: Maybe<EmailDnsSettings>;
};


export type ExportMembersInput = {
  type: ExportType;
  filters?: Maybe<MemberFilter>;
};

export type ExportMembersPayload = {
  __typename?: 'ExportMembersPayload';
  jobId: Scalars['String'];
};

export type ExportPostsPayload = {
  __typename?: 'ExportPostsPayload';
  jobId: Scalars['String'];
};

export enum ExportType {
  Member = 'MEMBER',
  MemberPlans = 'MEMBER_PLANS'
}

export type FailedPaymentPayload = {
  __typename?: 'FailedPaymentPayload';
  planId?: Maybe<Scalars['ID']>;
  priceId?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
};

export enum FilterType {
  Starts = 'STARTS',
  Equals = 'EQUALS'
}

export enum FontSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE'
}

export type GetLoginWithMemberTokenInput = {
  memberId: Scalars['ID'];
  domainUrl?: Maybe<Scalars['String']>;
};

export type GetMemberMetricsInput = {
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  interval: MemberMetricsInterval;
  timezone: Scalars['String'];
};

export type GetPlansInput = {
  status?: Maybe<PlanStatusInput>;
  orderBy?: Maybe<OrderByPlansInput>;
};

export type GetPostInput = {
  id: Scalars['ID'];
};

export type GetPostsFilter = {
  channelId?: Maybe<Scalars['ID']>;
  status?: Maybe<PostStatus>;
  memberId?: Maybe<Scalars['ID']>;
};

export type GetTeamInput = {
  teamId: Scalars['ID'];
};

export type GetTeamMembersInput = {
  teamId: Scalars['ID'];
};

export type GlobalCommentSettings = {
  __typename?: 'GlobalCommentSettings';
  moderateAllComments: Scalars['Boolean'];
  moderateNewMembers: Scalars['Boolean'];
  useDefaultRestrictedWords: Scalars['Boolean'];
  restrictedWords?: Maybe<Scalars['String']>;
  aiModerationEnabled?: Maybe<Scalars['Boolean']>;
  communityDescription?: Maybe<Scalars['String']>;
  aiSpamThreshold?: Maybe<Scalars['Int']>;
};

export type ImportMemberData = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  hashedPassword?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  metaData?: Maybe<Scalars['JSON']>;
  loginRedirect?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['JSON']>;
  plans?: Maybe<Array<Maybe<CreatePlanConnectionInput>>>;
};

export type ImportMembersInput = {
  members: Array<ImportMemberData>;
};

export type ImportMembersPayload = {
  __typename?: 'ImportMembersPayload';
  jobId: Scalars['String'];
  totalPendingJobs: Scalars['Int'];
};

export type ImportStripeProductInput = {
  productId: Scalars['String'];
};

export type InheritPermissionsFromPlanInput = {
  planId: Scalars['ID'];
  inheritFromPlanId: Scalars['ID'];
};


export type LinkPermissionGroupToMemberInput = {
  permissionGroupIds: Array<Scalars['ID']>;
  memberId: Scalars['ID'];
};

export type LinkPermissionGroupToPlanInput = {
  permissionGroupIds: Array<Scalars['ID']>;
  planId: Scalars['ID'];
};

export type LinkPermissionsToGroupInput = {
  permissionIds: Array<Scalars['ID']>;
  permissionGroupId: Scalars['ID'];
};

export type LinkPermissionsToMemberInput = {
  memberId: Scalars['ID'];
  permissionIds: Array<Scalars['ID']>;
};

export type LinkPermissionsToPlanInput = {
  permissionIds: Array<Scalars['ID']>;
  planId: Scalars['ID'];
};

export type LinkPlansToRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  planIds: Array<Scalars['ID']>;
};

export type LinkRestrictedUrlsToRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  urlIds: Array<Scalars['ID']>;
};

export type LoginMemberAuthProviderInput = {
  code: Scalars['String'];
  provider: AuthProvider;
};

export type LoginUserEmailPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginUserGoogleInput = {
  id_token: Scalars['String'];
  rewardfulId?: Maybe<Scalars['String']>;
};

export type Member = Node & {
  __typename?: 'Member';
  id: Scalars['ID'];
  auth: MemberAuth;
  customFields?: Maybe<Scalars['JSON']>;
  metaData?: Maybe<Scalars['JSON']>;
  planConnections?: Maybe<Array<Maybe<PlanConnection>>>;
  permissions?: Maybe<Permissions>;
  createdAt?: Maybe<Scalars['Date']>;
  lastLogin?: Maybe<Scalars['Date']>;
  json?: Maybe<Scalars['JSON']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  loginRedirect?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  memberNotes?: Maybe<MemberNote>;
  profileImage?: Maybe<Scalars['String']>;
  isModerator?: Maybe<Scalars['Boolean']>;
  trustStatus?: Maybe<MemberTrustStatus>;
  isNew?: Maybe<Scalars['Boolean']>;
};


export type MemberPlanConnectionsArgs = {
  input?: Maybe<MemberPlanConnectionsInput>;
};

export type MemberAuth = {
  __typename?: 'MemberAuth';
  email: Scalars['EmailAddress'];
  connectedProviders?: Maybe<Array<Maybe<MemberAuthProvider>>>;
};

export type MemberAuthForEvent = {
  __typename?: 'MemberAuthForEvent';
  email: Scalars['String'];
};

export type MemberAuthPayload = {
  __typename?: 'MemberAuthPayload';
  member: Member;
  payment?: Maybe<MemberAuthPurchasePayload>;
};

export type MemberAuthProvider = {
  __typename?: 'MemberAuthProvider';
  provider: AuthProviderType;
};

export type MemberAuthPurchasePayload = {
  __typename?: 'MemberAuthPurchasePayload';
  requirePayment?: Maybe<Array<Maybe<Scalars['ID']>>>;
  requireAuthentication?: Maybe<Array<Maybe<Scalars['ID']>>>;
  failedPayment?: Maybe<Array<Maybe<FailedPaymentPayload>>>;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  edges: Array<MemberEdge>;
  pageInfo: PageInfo;
  mode?: Maybe<MemberstackEnv>;
};

export type MemberEdge = {
  __typename?: 'MemberEdge';
  cursor: Scalars['String'];
  node: Member;
};

export type MemberEvent = {
  __typename?: 'MemberEvent';
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  memberAuth?: Maybe<MemberAuthForEvent>;
  createdAt: Scalars['Date'];
  source: MemberEventSource;
  event: MemberEventType;
  data?: Maybe<Scalars['JSON']>;
};

export type MemberEventConnection = {
  __typename?: 'MemberEventConnection';
  edges: Array<MemberEventEdge>;
  pageInfo: MemberEventPageInfo;
  mode?: Maybe<MemberstackEnv>;
};

export type MemberEventEdge = {
  __typename?: 'MemberEventEdge';
  cursor: Scalars['Int'];
  node: MemberEvent;
};

export type MemberEventFilter = {
  eventTypes?: Maybe<Array<Maybe<MemberEventType>>>;
  sourceTypes?: Maybe<Array<Maybe<MemberEventSource>>>;
  memberId?: Maybe<Scalars['ID']>;
  eventId?: Maybe<Scalars['ID']>;
};

export type MemberEventPageInfo = {
  __typename?: 'MemberEventPageInfo';
  endCursor?: Maybe<Scalars['Int']>;
  hasNextPage: Scalars['Boolean'];
};

export enum MemberEventSource {
  AdminApi = 'ADMIN_API',
  Package = 'PACKAGE',
  Dashboard = 'DASHBOARD',
  Zapier = 'ZAPIER',
  Make = 'MAKE',
  Stripe = 'STRIPE',
  PlanLogic = 'PLAN_LOGIC'
}

export enum MemberEventType {
  MemberCreated = 'MEMBER_CREATED',
  MemberLoggedin = 'MEMBER_LOGGEDIN',
  MemberUpdated = 'MEMBER_UPDATED',
  MemberPlanAdded = 'MEMBER_PLAN_ADDED',
  MemberPlanUpdated = 'MEMBER_PLAN_UPDATED',
  MemberPlanCanceled = 'MEMBER_PLAN_CANCELED'
}

export type MemberFilter = {
  planIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  noPlans?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<Maybe<PlanConnectionStatus>>>;
  hasComments?: Maybe<Scalars['Boolean']>;
  noComments?: Maybe<Scalars['Boolean']>;
};

export type MemberGeneratedPasswordPayload = {
  __typename?: 'MemberGeneratedPasswordPayload';
  member?: Maybe<Member>;
  generatedPassword: Scalars['String'];
};

export type MemberJson = {
  __typename?: 'MemberJSON';
  json?: Maybe<Scalars['JSON']>;
};

export enum MemberMetricsInterval {
  Hour = 'HOUR',
  Day = 'DAY',
  Month = 'MONTH'
}

export enum MemberMetricsReport {
  TotalMemberCountByType = 'TOTAL_MEMBER_COUNT_BY_TYPE',
  TotalMemberCountCompareLastMonth = 'TOTAL_MEMBER_COUNT_COMPARE_LAST_MONTH',
  PrevMonthMemberCountCompareThisMonth = 'PREV_MONTH_MEMBER_COUNT_COMPARE_THIS_MONTH',
  MemberSignupCount_60DaysAnd_30Days = 'MEMBER_SIGNUP_COUNT_60_DAYS_AND_30_DAYS'
}

export type MemberNote = {
  __typename?: 'MemberNote';
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MemberPlanConnections = {
  __typename?: 'MemberPlanConnections';
  memberId: Scalars['ID'];
  planConnections?: Maybe<Array<Maybe<PlanConnection>>>;
};

export type MemberPlanConnectionsInput = {
  status?: Maybe<Array<Maybe<PlanConnectionStatus>>>;
};


export type MemberResetPasswordEmailInput = {
  email: Scalars['String'];
};

export type MemberResetPasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MemberTeamConnection = {
  __typename?: 'MemberTeamConnection';
  role: MemberTeamRole;
  memberId: Scalars['ID'];
  member: Member;
};

export enum MemberTeamRole {
  Owner = 'OWNER',
  Member = 'MEMBER'
}

export type MemberToken = {
  __typename?: 'MemberToken';
  token?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Date']>;
};

export enum MemberTrustStatus {
  Trusted = 'TRUSTED',
  Default = 'DEFAULT',
  Banned = 'BANNED',
  ShadowBanned = 'SHADOW_BANNED'
}

export enum MemberstackEnv {
  Sandbox = 'SANDBOX',
  Live = 'LIVE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createApp: App;
  updateApp: App;
  deleteApp: App;
  restoreApp: App;
  updateDefaultRedirects: Redirects;
  addDomain: Domain;
  verifyDomain: Domain;
  updateDomain: Domain;
  removeDomain: Scalars['String'];
  addEmailDomain: Emails;
  removeEmailDomain: Emails;
  verifyEmailDomain: Emails;
  updateCustomDomain: CustomDomain;
  createCheckoutSession: StripeUrl;
  createBillingPortalSession: StripeUrl;
  createPlan: Plan;
  updatePlan: Plan;
  deletePlan: Plan;
  orderPlans: Array<Plan>;
  addFreePlan: Plan;
  removeFreePlan: Plan;
  removeOneTimePlan: Plan;
  updatePlanLogic: PlanLogic;
  createStripeCustomer: Member;
  createPermission: Permission;
  updatePermission: Permission;
  deletePermission: Permission;
  createSSOApp: SsoApp;
  deleteSSOApp: Scalars['ID'];
  createSSOClient: AuthProviderConfig;
  updateSSOClient: AuthProviderConfig;
  removeSSOClient: Scalars['ID'];
  updateEmails: Emails;
  createRestrictedUrl: RestrictedUrl;
  deleteRestrictedUrl: RestrictedUrl;
  updateRestrictedUrl: RestrictedUrl;
  linkRestrictedUrlsToRestrictedUrlGroup: RestrictedUrlGroup;
  detachRestrictedUrlsFromRestrictedUrlGroup: RestrictedUrlGroup;
  linkPlansToRestrictedUrlGroup: RestrictedUrlGroup;
  detachPlansFromRestrictedUrlGroup: RestrictedUrlGroup;
  createRestrictedUrlGroup: RestrictedUrlGroup;
  updateRestrictedUrlGroup: RestrictedUrlGroup;
  deleteRestrictedUrlGroup: RestrictedUrlGroup;
  createCustomContent: CustomContent;
  updateCustomContent: CustomContent;
  deleteCustomContent: CustomContent;
  linkPermissionsToPlan: Plan;
  detachPermissionFromPlan: Plan;
  linkPermissionsToMember: Member;
  detachPermissionFromMember: Member;
  createPermissionGroup: PermissionGroup;
  updatePermissionGroup: PermissionGroup;
  deletePermissionGroup: PermissionGroup;
  linkPermissionsToGroup: PermissionGroup;
  detachPermissionsFromGroup: PermissionGroup;
  linkPermissionGroupsToPlan: Plan;
  detachPermissionGroupsFromPlan: Plan;
  linkPermissionGroupsToMember: Member;
  detachPermissionGroupsFromMember: Member;
  connectStripe?: Maybe<App>;
  disconnectStripe?: Maybe<App>;
  signupUserEmailPassword: User;
  loginUserEmailPassword: User;
  loginUserGoogle: User;
  connectUserToGoogle: User;
  disconnectUserFromGoogle: User;
  signupUserGoogle: User;
  authenticateUserWith2FA: User;
  enableUser2FA: User;
  setupUser2FA: UserTwoFactorAuthPayload;
  disableUser2FA: User;
  logoutUser: User;
  generateZendeskToken: Scalars['String'];
  updateUserProfile: User;
  updateUserAuth: User;
  sendUserResetPasswordEmail: Scalars['String'];
  resetUserPassword: User;
  deleteUser: User;
  addUserToApp: UserAppConnection;
  removeUserFromApp: UserAppConnection;
  transferAppOwnership: UserAppConnection;
  createPrice: Price;
  updatePrice: Price;
  reactivatePrice: Price;
  deactivatePrice: Price;
  deletePrice: Price;
  createWebhook: Webhook;
  updateWebhook: Webhook;
  deleteWebhook: Scalars['ID'];
  importStripeProduct: Plan;
  signupMemberEmailPassword: MemberAuthPayload;
  connectAuthProvider: Member;
  disconnectAuthProvider: Member;
  updateMember: Member;
  updateMemberAuth: Member;
  updateMemberNote: Member;
  generateMemberPassword: MemberGeneratedPasswordPayload;
  deleteMember: Scalars['ID'];
  importMembers: ImportMembersPayload;
  exportMembers: ExportMembersPayload;
  exportPosts: ExportPostsPayload;
  createCustomField: CustomField;
  updateCustomField: CustomField;
  deleteCustomField: Scalars['ID'];
  orderCustomFields: Array<CustomField>;
  orderTableCustomFields: Array<CustomField>;
  updatePasswordlessSettings: PasswordlessSettingsPayload;
  removeAllMembers: RemoveAllMembersPayload;
  removeSelectMembers: RemoveSelectMembersPayload;
  resyncStripeMember: ResyncStripeMemberPayload;
  updateBranding: Branding;
  updateClientMessageText: ClientMessageText;
  updateSSOText: SsoText;
  generateAPIKeys?: Maybe<ApiKeys>;
  setWebflowSite: App;
  deauthorizeWebflow: User;
  deletePost: Scalars['ID'];
  updatePost: Post;
  updateChannel: Channel;
  createChannel: Channel;
  updateGlobalCommentSettings: GlobalCommentSettings;
  removeTeamMember: Scalars['ID'];
  regenerateTeamInviteToken: Team;
};


export type MutationCreateAppArgs = {
  input: CreateAppInput;
};


export type MutationUpdateAppArgs = {
  input: UpdateAppInput;
};


export type MutationDeleteAppArgs = {
  input: DeleteAppInput;
};


export type MutationRestoreAppArgs = {
  input: RestoreAppInput;
};


export type MutationUpdateDefaultRedirectsArgs = {
  input: RedirectsInput;
};


export type MutationAddDomainArgs = {
  input: AddDomainToAppInput;
};


export type MutationVerifyDomainArgs = {
  input: VerifyDomainInput;
};


export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};


export type MutationRemoveDomainArgs = {
  input: RemoveDomainInput;
};


export type MutationAddEmailDomainArgs = {
  input: AddEmailDomainInput;
};


export type MutationUpdateCustomDomainArgs = {
  input: UpdateCustomDomainInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreateBillingPortalSessionArgs = {
  input: CreateBillingPortalSessionInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationDeletePlanArgs = {
  input: DeletePlanInput;
};


export type MutationOrderPlansArgs = {
  input: OrderPlansInput;
};


export type MutationAddFreePlanArgs = {
  input: AddFreePlanInput;
};


export type MutationRemoveFreePlanArgs = {
  input: RemoveFreePlanInput;
};


export type MutationRemoveOneTimePlanArgs = {
  input: RemoveOneTimePlanInput;
};


export type MutationUpdatePlanLogicArgs = {
  input: UpdatePlanLogicInput;
};


export type MutationCreateStripeCustomerArgs = {
  input: CreateStripeCustomerInput;
};


export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};


export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};


export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput;
};


export type MutationCreateSsoAppArgs = {
  input: CreateSsoAppInput;
};


export type MutationDeleteSsoAppArgs = {
  input: DeleteSsoAppInput;
};


export type MutationCreateSsoClientArgs = {
  input: CreateSsoClientInput;
};


export type MutationUpdateSsoClientArgs = {
  input: UpdateSsoClientInput;
};


export type MutationRemoveSsoClientArgs = {
  input: RemoveSsoClientInput;
};


export type MutationUpdateEmailsArgs = {
  input: UpdateEmailsInput;
};


export type MutationCreateRestrictedUrlArgs = {
  input: CreateRestrictedUrlInput;
};


export type MutationDeleteRestrictedUrlArgs = {
  input: DeleteRestrictedUrlInput;
};


export type MutationUpdateRestrictedUrlArgs = {
  input: UpdateRestrictedUrlInput;
};


export type MutationLinkRestrictedUrlsToRestrictedUrlGroupArgs = {
  input: LinkRestrictedUrlsToRestrictedUrlGroupInput;
};


export type MutationDetachRestrictedUrlsFromRestrictedUrlGroupArgs = {
  input: DetachRestrictedUrlsFromRestrictedUrlGroupInput;
};


export type MutationLinkPlansToRestrictedUrlGroupArgs = {
  input: LinkPlansToRestrictedUrlGroupInput;
};


export type MutationDetachPlansFromRestrictedUrlGroupArgs = {
  input: DetachPlansFromRestrictedUrlGroupInput;
};


export type MutationCreateRestrictedUrlGroupArgs = {
  input: CreateRestrictedUrlGroupInput;
};


export type MutationUpdateRestrictedUrlGroupArgs = {
  input: UpdateRestrictedUrlGroupInput;
};


export type MutationDeleteRestrictedUrlGroupArgs = {
  input: DeleteRestrictedUrlGroupInput;
};


export type MutationCreateCustomContentArgs = {
  input: CreateCustomContentExistingGroupInput;
};


export type MutationUpdateCustomContentArgs = {
  input: UpdateCustomContentInput;
};


export type MutationDeleteCustomContentArgs = {
  input: DeleteCustomContentInput;
};


export type MutationLinkPermissionsToPlanArgs = {
  input: LinkPermissionsToPlanInput;
};


export type MutationDetachPermissionFromPlanArgs = {
  input: DetachPermissionFromPlanInput;
};


export type MutationLinkPermissionsToMemberArgs = {
  input: LinkPermissionsToMemberInput;
};


export type MutationDetachPermissionFromMemberArgs = {
  input: DetachPermissionFromMemberInput;
};


export type MutationCreatePermissionGroupArgs = {
  input: CreatePermissionGroupInput;
};


export type MutationUpdatePermissionGroupArgs = {
  input: UpdatePermissionGroupInput;
};


export type MutationDeletePermissionGroupArgs = {
  input: DeletePermissionGroupInput;
};


export type MutationLinkPermissionsToGroupArgs = {
  input: LinkPermissionsToGroupInput;
};


export type MutationDetachPermissionsFromGroupArgs = {
  input: DetachPermissionsFromGroupInput;
};


export type MutationLinkPermissionGroupsToPlanArgs = {
  input: LinkPermissionGroupToPlanInput;
};


export type MutationDetachPermissionGroupsFromPlanArgs = {
  input: DetachPermissionGroupFromPlanInput;
};


export type MutationLinkPermissionGroupsToMemberArgs = {
  input: LinkPermissionGroupToMemberInput;
};


export type MutationDetachPermissionGroupsFromMemberArgs = {
  input: DetachPermissionGroupFromMemberInput;
};


export type MutationConnectStripeArgs = {
  input?: Maybe<ConnectStripeAppInput>;
};


export type MutationSignupUserEmailPasswordArgs = {
  input: SignupUserEmailPasswordInput;
};


export type MutationLoginUserEmailPasswordArgs = {
  input: LoginUserEmailPasswordInput;
};


export type MutationLoginUserGoogleArgs = {
  input: LoginUserGoogleInput;
};


export type MutationConnectUserToGoogleArgs = {
  input: LoginUserGoogleInput;
};


export type MutationSignupUserGoogleArgs = {
  input: LoginUserGoogleInput;
};


export type MutationAuthenticateUserWith2FaArgs = {
  input: AuthenticateUserWith2FaInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateUserAuthArgs = {
  input: UpdateUserAuthInput;
};


export type MutationSendUserResetPasswordEmailArgs = {
  input: UserResetPasswordEmailInput;
};


export type MutationResetUserPasswordArgs = {
  input: UserResetPasswordInput;
};


export type MutationAddUserToAppArgs = {
  input: AddUserToAppInput;
};


export type MutationRemoveUserFromAppArgs = {
  input: RemoveUserFromAppInput;
};


export type MutationTransferAppOwnershipArgs = {
  input: TransferAppOwnershipInput;
};


export type MutationCreatePriceArgs = {
  input: CreatePriceInput;
};


export type MutationUpdatePriceArgs = {
  input: UpdatePriceInput;
};


export type MutationReactivatePriceArgs = {
  input: ReactivatePriceInput;
};


export type MutationDeactivatePriceArgs = {
  input: DeactivatePriceInput;
};


export type MutationDeletePriceArgs = {
  input: DeletePriceInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationImportStripeProductArgs = {
  input: ImportStripeProductInput;
};


export type MutationSignupMemberEmailPasswordArgs = {
  input: SignupMemberEmailPasswordInput;
};


export type MutationConnectAuthProviderArgs = {
  input: ConnectAuthProviderInput;
};


export type MutationDisconnectAuthProviderArgs = {
  input: DisconnectAuthProviderInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemberAuthArgs = {
  input: UpdateMemberAuthInput;
};


export type MutationUpdateMemberNoteArgs = {
  input: UpdateMemberNoteInput;
};


export type MutationGenerateMemberPasswordArgs = {
  input: GenerateMemberPasswordInput;
};


export type MutationDeleteMemberArgs = {
  input: DeleteMemberInput;
};


export type MutationImportMembersArgs = {
  input: ImportMembersInput;
};


export type MutationExportMembersArgs = {
  input: ExportMembersInput;
};


export type MutationCreateCustomFieldArgs = {
  input: CreateCustomFieldInput;
};


export type MutationUpdateCustomFieldArgs = {
  input: UpdateCustomFieldInput;
};


export type MutationDeleteCustomFieldArgs = {
  input: DeleteCustomFieldInput;
};


export type MutationOrderCustomFieldsArgs = {
  input: OrderCustomFieldsInput;
};


export type MutationOrderTableCustomFieldsArgs = {
  input: OrderCustomFieldsInput;
};


export type MutationUpdatePasswordlessSettingsArgs = {
  input: UpdatePasswordlessSettingsInput;
};


export type MutationRemoveAllMembersArgs = {
  input: RemoveAllMembersInput;
};


export type MutationRemoveSelectMembersArgs = {
  input: RemoveSelectMembersInput;
};


export type MutationResyncStripeMemberArgs = {
  input: ResyncStripeMemberInput;
};


export type MutationUpdateBrandingArgs = {
  input: UpdateBrandingInput;
};


export type MutationUpdateClientMessageTextArgs = {
  input: UpdateClientMessageTextInput;
};


export type MutationUpdateSsoTextArgs = {
  input: UpdateSsoTextInput;
};


export type MutationSetWebflowSiteArgs = {
  input: SetWebflowSiteInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationUpdateGlobalCommentSettingsArgs = {
  input: UpdateGlobalCommentSettingsInput;
};


export type MutationRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};


export type MutationRegenerateTeamInviteTokenArgs = {
  input: RegenerateTeamInviteTokenInput;
};

export type Node = {
  id: Scalars['ID'];
};

export enum OrderByInput {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum OrderByPlansInput {
  Priority = 'PRIORITY',
  CreatedAt = 'CREATED_AT'
}

export type OrderCustomFieldsInput = {
  orders?: Maybe<Array<Maybe<CustomFieldOrderInput>>>;
};

export type OrderPlansInput = {
  orders?: Maybe<Array<Maybe<PlanOrderInput>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
};

export type PasswordlessSettingsPayload = {
  __typename?: 'PasswordlessSettingsPayload';
  passwordlessEnabled: Scalars['Boolean'];
  defaultToPasswordless: Scalars['Boolean'];
};

export enum PauseCollection {
  MarkUncollectible = 'mark_uncollectible',
  KeepAsDraft = 'keep_as_draft',
  Void = 'void'
}

export type Permission = Node & {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PermissionGroup = Node & {
  __typename?: 'PermissionGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type Permissions = {
  __typename?: 'Permissions';
  manuallyGranted?: Maybe<Array<Maybe<Scalars['String']>>>;
  all?: Maybe<Array<Maybe<Scalars['String']>>>;
  planInherited?: Maybe<Array<Maybe<PlanInheritedPermission>>>;
};

export type Plan = Node & {
  __typename?: 'Plan';
  allowedDomains?: Maybe<Array<Maybe<Scalars['EmailAddress']>>>;
  copiedToLive?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPersisted: Scalars['Boolean'];
  limitMembers?: Maybe<Scalars['Boolean']>;
  logic?: Maybe<PlanLogic>;
  memberCount?: Maybe<Scalars['Int']>;
  memberLimit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  permissionGroups?: Maybe<Array<Maybe<PermissionGroup>>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  prices?: Maybe<Array<Maybe<Price>>>;
  priority?: Maybe<Scalars['Int']>;
  redirects?: Maybe<Redirects>;
  restrictToAdmin?: Maybe<Scalars['Boolean']>;
  status: PlanStatus;
  teamAccountInviteSignupLink?: Maybe<Scalars['String']>;
  teamAccountUpgradeLink?: Maybe<Scalars['String']>;
  teamAccountsEnabled?: Maybe<Scalars['Boolean']>;
};

export type PlanConnection = {
  __typename?: 'PlanConnection';
  id: Scalars['ID'];
  plan: PlanConnectionPlan;
  active: Scalars['Boolean'];
  status: PlanConnectionStatus;
  type: PlanConnectionType;
  payment?: Maybe<PlanConnectionPayment>;
  team?: Maybe<PlanConnectionTeam>;
};

export type PlanConnectionPayment = {
  __typename?: 'PlanConnectionPayment';
  status: PlanConnectionPaymentStatus;
  lastBillingDate?: Maybe<Scalars['Int']>;
  nextBillingDate?: Maybe<Scalars['Int']>;
  freeTrialEndDate?: Maybe<Scalars['Int']>;
  cancelAtDate?: Maybe<Scalars['Int']>;
  pauseCollection?: Maybe<PauseCollection>;
  pauseResumeAt?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  priceId: Scalars['ID'];
  interval?: Maybe<PriceInterval>;
};

export enum PlanConnectionPaymentStatus {
  Paid = 'PAID',
  RequiresPayment = 'REQUIRES_PAYMENT',
  RequiresAuthentication = 'REQUIRES_AUTHENTICATION'
}

export type PlanConnectionPlan = {
  __typename?: 'PlanConnectionPlan';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum PlanConnectionStatus {
  Active = 'ACTIVE',
  Trialing = 'TRIALING',
  RequiresPayment = 'REQUIRES_PAYMENT',
  RequiresAuthentication = 'REQUIRES_AUTHENTICATION',
  Canceled = 'CANCELED'
}

export type PlanConnectionTeam = {
  __typename?: 'PlanConnectionTeam';
  isTeamPlan: Scalars['Boolean'];
  isTeamOwner: Scalars['Boolean'];
  teamOwnerId?: Maybe<Scalars['ID']>;
  teamId?: Maybe<Scalars['ID']>;
};

export enum PlanConnectionType {
  Free = 'FREE',
  Subscription = 'SUBSCRIPTION',
  Onetime = 'ONETIME'
}

export type PlanInheritedPermission = {
  __typename?: 'PlanInheritedPermission';
  permission?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
};

export type PlanLogic = {
  __typename?: 'PlanLogic';
  addedLogic?: Maybe<PlanLogicSingle>;
  removedLogic?: Maybe<PlanLogicSingle>;
};

export type PlanLogicSingle = {
  __typename?: 'PlanLogicSingle';
  add?: Maybe<Array<Maybe<Scalars['String']>>>;
  remove?: Maybe<Array<Maybe<Scalars['String']>>>;
  cancelRecurring?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeAllFree?: Maybe<Scalars['Boolean']>;
  cancelAllRecurring?: Maybe<Scalars['Boolean']>;
};

export type PlanLogicSingleInput = {
  add?: Maybe<Array<Maybe<Scalars['String']>>>;
  remove?: Maybe<Array<Maybe<Scalars['String']>>>;
  cancelRecurring?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeAllFree?: Maybe<Scalars['Boolean']>;
  cancelAllRecurring?: Maybe<Scalars['Boolean']>;
};

export type PlanOrderInput = {
  planId: Scalars['ID'];
  priority: Scalars['Int'];
};

export type PlanPaymentInput = {
  coupon?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['ID']>;
  stripe?: Maybe<StripePaymentMethodInput>;
};

export enum PlanStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum PlanStatusInput {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  internalOrder: Scalars['Int'];
  status: PostStatus;
  content: Scalars['String'];
  likedByCount: Scalars['Int'];
  dislikedByCount: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  aiSpamLevel?: Maybe<Scalars['Int']>;
  threads?: Maybe<Array<Thread>>;
  memberId: Scalars['ID'];
  member?: Maybe<Member>;
  isNew?: Maybe<Scalars['Boolean']>;
};

export type PostCreator = {
  __typename?: 'PostCreator';
  id: Scalars['ID'];
  email: Scalars['String'];
  profileImage?: Maybe<Scalars['String']>;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor: Scalars['Int'];
  node: Post;
};

export type PostLink = {
  __typename?: 'PostLink';
  url?: Maybe<Scalars['String']>;
};

export enum PostOrder {
  Oldest = 'OLDEST',
  Newest = 'NEWEST'
}

export type PostPage = {
  __typename?: 'PostPage';
  edges: Array<PostEdge>;
  pageInfo: PostPageInfo;
};

export type PostPageInfo = {
  __typename?: 'PostPageInfo';
  endCursor?: Maybe<Scalars['Int']>;
};

export enum PostStatus {
  Published = 'PUBLISHED',
  Hidden = 'HIDDEN',
  Spam = 'SPAM'
}

export type Price = Node & {
  __typename?: 'Price';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  currency?: Maybe<PriceCurrency>;
  description?: Maybe<Scalars['String']>;
  expiration?: Maybe<PriceExpiration>;
  freeTrial?: Maybe<PriceFreeTrial>;
  id: Scalars['ID'];
  interval?: Maybe<PriceInterval>;
  isPersisted: Scalars['Boolean'];
  maxTeamMembers?: Maybe<Scalars['Int']>;
  memberCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  setupFee?: Maybe<PriceSetupFee>;
  status: PriceStatus;
  taxType?: Maybe<TaxType>;
  teamAccountsEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PriceType>;
};

export enum PriceCurrency {
  Bif = 'bif',
  Clp = 'clp',
  Djf = 'djf',
  Gnf = 'gnf',
  Jpy = 'jpy',
  Kmf = 'kmf',
  Krw = 'krw',
  Mga = 'mga',
  Pyg = 'pyg',
  Rwf = 'rwf',
  Ugx = 'ugx',
  Vnd = 'vnd',
  Vuv = 'vuv',
  Xaf = 'xaf',
  Xof = 'xof',
  Xpf = 'xpf',
  Usd = 'usd',
  Aed = 'aed',
  All = 'all',
  Amd = 'amd',
  Ang = 'ang',
  Aud = 'aud',
  Awg = 'awg',
  Azn = 'azn',
  Bam = 'bam',
  Bbd = 'bbd',
  Bdt = 'bdt',
  Bgn = 'bgn',
  Bmd = 'bmd',
  Bnd = 'bnd',
  Bsd = 'bsd',
  Bwp = 'bwp',
  Bzd = 'bzd',
  Cad = 'cad',
  Cdf = 'cdf',
  Chf = 'chf',
  Cny = 'cny',
  Dkk = 'dkk',
  Dop = 'dop',
  Dzd = 'dzd',
  Egp = 'egp',
  Etb = 'etb',
  Eur = 'eur',
  Fjd = 'fjd',
  Gbp = 'gbp',
  Gel = 'gel',
  Gip = 'gip',
  Gmd = 'gmd',
  Gyd = 'gyd',
  Hkd = 'hkd',
  Hrk = 'hrk',
  Htg = 'htg',
  Idr = 'idr',
  Ils = 'ils',
  Isk = 'isk',
  Jmd = 'jmd',
  Kes = 'kes',
  Kgs = 'kgs',
  Khr = 'khr',
  Kyd = 'kyd',
  Kzt = 'kzt',
  Lbp = 'lbp',
  Lkr = 'lkr',
  Lrd = 'lrd',
  Lsl = 'lsl',
  Mad = 'mad',
  Mdl = 'mdl',
  Mkd = 'mkd',
  Mmk = 'mmk',
  Mnt = 'mnt',
  Mop = 'mop',
  Mro = 'mro',
  Mvr = 'mvr',
  Mwk = 'mwk',
  Mxn = 'mxn',
  Myr = 'myr',
  Mzn = 'mzn',
  Nad = 'nad',
  Ngn = 'ngn',
  Nok = 'nok',
  Npr = 'npr',
  Nzd = 'nzd',
  Pgk = 'pgk',
  Php = 'php',
  Pkr = 'pkr',
  Pln = 'pln',
  Qar = 'qar',
  Ron = 'ron',
  Rsd = 'rsd',
  Rub = 'rub',
  Sar = 'sar',
  Sbd = 'sbd',
  Scr = 'scr',
  Sek = 'sek',
  Sgd = 'sgd',
  Sll = 'sll',
  Sos = 'sos',
  Szl = 'szl',
  Thb = 'thb',
  Tjs = 'tjs',
  Top = 'top',
  Try = 'try',
  Ttd = 'ttd',
  Twd = 'twd',
  Tzs = 'tzs',
  Uah = 'uah',
  Uzs = 'uzs',
  Wst = 'wst',
  Xcd = 'xcd',
  Yer = 'yer',
  Zar = 'zar',
  Zmw = 'zmw',
  Inr = 'inr',
  Czk = 'czk',
  Brl = 'brl'
}

export type PriceExpiration = {
  __typename?: 'PriceExpiration';
  count?: Maybe<Scalars['Int']>;
  interval?: Maybe<PriceExpirationInterval>;
};

export enum PriceExpirationInterval {
  Months = 'MONTHS',
  Days = 'DAYS'
}

export type PriceFreeTrial = {
  __typename?: 'PriceFreeTrial';
  enabled?: Maybe<Scalars['Boolean']>;
  days?: Maybe<Scalars['Int']>;
};

export type PriceInterval = {
  __typename?: 'PriceInterval';
  type?: Maybe<PriceIntervalType>;
  count?: Maybe<Scalars['Int']>;
};

export enum PriceIntervalType {
  Yearly = 'YEARLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type PriceSetupFee = {
  __typename?: 'PriceSetupFee';
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export enum PriceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Draft = 'DRAFT'
}

export enum PriceType {
  Subscription = 'SUBSCRIPTION',
  Onetime = 'ONETIME'
}

export type Query = {
  __typename?: 'Query';
  currentApp?: Maybe<App>;
  currentUser?: Maybe<User>;
  currentMember?: Maybe<Member>;
  defaultAuthProviders?: Maybe<Array<Maybe<AuthProviderConfig>>>;
  getPlan?: Maybe<Plan>;
  getPlans?: Maybe<Array<Maybe<Plan>>>;
  getMembers?: Maybe<MemberConnection>;
  getMembersCount?: Maybe<Scalars['Int']>;
  getMembersPlanConnections?: Maybe<Array<Maybe<MemberPlanConnections>>>;
  getMemberEvents?: Maybe<MemberEventConnection>;
  getUsers?: Maybe<UserConnection>;
  getApps?: Maybe<Array<Maybe<App>>>;
  getPermission: Permission;
  getPermissions?: Maybe<Array<Maybe<Permission>>>;
  getWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  getWebhook?: Maybe<Webhook>;
  getSSOApps?: Maybe<Array<Maybe<SsoApp>>>;
  getSSOClients?: Maybe<Array<Maybe<AuthProviderConfig>>>;
  getSSOClient?: Maybe<AuthProviderConfig>;
  getBranding: Branding;
  getCustomFields?: Maybe<Array<Maybe<CustomField>>>;
  getContentGroups?: Maybe<Array<Maybe<RestrictedUrlGroup>>>;
  getContentGroup?: Maybe<RestrictedUrlGroup>;
  getEmails?: Maybe<Emails>;
  getClientMessageText?: Maybe<ClientMessageText>;
  getSSOText?: Maybe<SsoText>;
  getRedirects?: Maybe<Redirects>;
  getSvixAppPortal?: Maybe<Scalars['String']>;
  getAccessLogs?: Maybe<Array<Maybe<AccessLog>>>;
  getAppEvents?: Maybe<Array<Maybe<AppEvent>>>;
  getMemberMetrics?: Maybe<Scalars['JSON']>;
  getMemberMetricsByReport?: Maybe<Scalars['JSON']>;
  getWebflowURLVerification?: Maybe<Scalars['Boolean']>;
  getLoginWithMemberToken?: Maybe<Scalars['String']>;
  getHasPaidPlans?: Maybe<Scalars['Boolean']>;
  getTeam?: Maybe<Team>;
  getTeamMembers?: Maybe<Array<Maybe<MemberTeamConnection>>>;
  getWebflowAuthorizationUrl: Scalars['String'];
  getWebflowSites?: Maybe<Array<Maybe<WebflowSite>>>;
  getWebflowDomains?: Maybe<Array<Maybe<WebflowDomain>>>;
  getWebflowUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  getGlobalCommentSettings?: Maybe<GlobalCommentSettings>;
  getChannels?: Maybe<Array<Maybe<Channel>>>;
  getPosts?: Maybe<PostPage>;
  getPost?: Maybe<Post>;
  getPostLink?: Maybe<PostLink>;
};


export type QueryCurrentMemberArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetPlanArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlansArgs = {
  input?: Maybe<GetPlansInput>;
};


export type QueryGetMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  order?: Maybe<OrderByInput>;
  filters?: Maybe<MemberFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetMembersPlanConnectionsArgs = {
  memberIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGetMemberEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Int']>;
  filters?: Maybe<MemberEventFilter>;
};


export type QueryGetUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  order?: Maybe<OrderByInput>;
};


export type QueryGetPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryGetWebhookArgs = {
  id: Scalars['ID'];
};


export type QueryGetSsoClientArgs = {
  id: Scalars['ID'];
};


export type QueryGetContentGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetMemberMetricsArgs = {
  input?: Maybe<GetMemberMetricsInput>;
};


export type QueryGetMemberMetricsByReportArgs = {
  report?: Maybe<MemberMetricsReport>;
};


export type QueryGetWebflowUrlVerificationArgs = {
  url?: Maybe<Scalars['String']>;
};


export type QueryGetLoginWithMemberTokenArgs = {
  input?: Maybe<GetLoginWithMemberTokenInput>;
};


export type QueryGetTeamArgs = {
  input?: Maybe<GetTeamInput>;
};


export type QueryGetTeamMembersArgs = {
  input?: Maybe<GetTeamMembersInput>;
};


export type QueryGetPostsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Int']>;
  order?: Maybe<PostOrder>;
  filters?: Maybe<GetPostsFilter>;
};


export type QueryGetPostArgs = {
  input?: Maybe<GetPostInput>;
};


export type QueryGetPostLinkArgs = {
  id: Scalars['ID'];
};

export type ReactivatePriceInput = {
  priceId: Scalars['ID'];
};

export type Redirects = {
  __typename?: 'Redirects';
  afterSignup?: Maybe<Scalars['String']>;
  afterLogin?: Maybe<Scalars['String']>;
  afterLogout?: Maybe<Scalars['String']>;
  afterPurchase?: Maybe<Scalars['String']>;
  afterCancel?: Maybe<Scalars['String']>;
  afterReplace?: Maybe<Scalars['String']>;
  verificationRequired?: Maybe<Scalars['String']>;
};

export type RedirectsInput = {
  afterSignup?: Maybe<Scalars['String']>;
  afterLogin?: Maybe<Scalars['String']>;
  afterLogout?: Maybe<Scalars['String']>;
  afterPurchase?: Maybe<Scalars['String']>;
  afterCancel?: Maybe<Scalars['String']>;
  afterReplace?: Maybe<Scalars['String']>;
  verificationRequired?: Maybe<Scalars['String']>;
};

export type RegenerateTeamInviteTokenInput = {
  teamId: Scalars['ID'];
};

export type RemoveAllMembersInput = {
  mode?: Maybe<MemberstackEnv>;
};

export type RemoveAllMembersPayload = {
  __typename?: 'RemoveAllMembersPayload';
  jobId: Scalars['String'];
};

export type RemoveDomainInput = {
  domain: Scalars['String'];
};

export type RemoveFreePlanInput = {
  planId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type RemoveMemberCardInput = {
  memberId?: Maybe<Scalars['ID']>;
  cardId: Scalars['ID'];
};

export type RemoveOneTimePlanInput = {
  planId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type RemoveSsoClientInput = {
  id: Scalars['ID'];
};

export type RemoveSelectMembersInput = {
  membersToDelete: Array<Maybe<Scalars['ID']>>;
};

export type RemoveSelectMembersPayload = {
  __typename?: 'RemoveSelectMembersPayload';
  count: Scalars['Int'];
};

export type RemoveTeamMemberInput = {
  teamId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type RemoveUserFromAppInput = {
  userId: Scalars['ID'];
};

export type RestoreAppInput = {
  appId: Scalars['ID'];
};

export type RestrictedUrl = Node & {
  __typename?: 'RestrictedUrl';
  id: Scalars['ID'];
  url: Scalars['String'];
  filter: Scalars['String'];
};

export type RestrictedUrlGroup = Node & {
  __typename?: 'RestrictedUrlGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  redirect: Scalars['String'];
  urls: Array<Maybe<RestrictedUrl>>;
  plans: Array<Maybe<Plan>>;
  allowAllMembers: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  customContent: Array<Maybe<CustomContent>>;
};

export type ResyncStripeMemberInput = {
  memberId: Scalars['ID'];
};

export type ResyncStripeMemberPayload = {
  __typename?: 'ResyncStripeMemberPayload';
  jobId: Scalars['String'];
};

export type RewardfulData = {
  referral?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
};

export type SsoApp = {
  __typename?: 'SSOApp';
  id: Scalars['ID'];
  name: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  redirectUris?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SsoText = {
  __typename?: 'SSOText';
  textObject?: Maybe<Scalars['JSON']>;
};

export type SetWebflowSiteInput = {
  siteId: Scalars['String'];
  appId: Scalars['String'];
};

export enum Shadows {
  None = 'NONE',
  Small = 'SMALL',
  Large = 'LARGE'
}

export type SignupMemberAuthProviderInput = {
  code: Scalars['String'];
  provider: AuthProvider;
  customFields?: Maybe<Scalars['JSON']>;
  metaData?: Maybe<Scalars['JSON']>;
  plans?: Maybe<Array<Maybe<CreatePlanConnectionInput>>>;
  payment?: Maybe<PlanPaymentInput>;
  loginRedirect?: Maybe<Scalars['String']>;
};

export type SignupMemberEmailPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  customFields?: Maybe<Scalars['JSON']>;
  metaData?: Maybe<Scalars['JSON']>;
  plans?: Maybe<Array<Maybe<CreatePlanConnectionInput>>>;
  payment?: Maybe<PlanPaymentInput>;
  loginRedirect?: Maybe<Scalars['String']>;
  rewardfulData?: Maybe<RewardfulData>;
  inviteToken?: Maybe<Scalars['String']>;
};

export type SignupUserEmailPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  consent: Scalars['Boolean'];
  subscribed?: Maybe<Scalars['Boolean']>;
  rewardfulId?: Maybe<Scalars['String']>;
};

export enum Stack {
  React = 'REACT',
  Webflow = 'WEBFLOW',
  Vanilla = 'VANILLA'
}

export type StripePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
};

export type StripeUrl = {
  __typename?: 'StripeUrl';
  url: Scalars['String'];
};

export type Style = {
  __typename?: 'Style';
  fontSize: FontSize;
  components: ButtonComponentStyles;
  buttons: ButtonComponentStyles;
};

export enum TaxType {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  inviteToken: Scalars['String'];
  maxTeamMembers?: Maybe<Scalars['Int']>;
  currentTeamMemberCount?: Maybe<Scalars['Int']>;
  inviteLink?: Maybe<Scalars['String']>;
};

export type Thread = {
  __typename?: 'Thread';
  id: Scalars['ID'];
  channel: Channel;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  internalOrder: Scalars['Int'];
  post: Post;
  aiSpamLevel?: Maybe<Scalars['Int']>;
  content: Scalars['String'];
  member: Member;
};


export type TransferAppOwnershipInput = {
  userId: Scalars['ID'];
};


export type UpdateAppInput = {
  name?: Maybe<Scalars['String']>;
  stack?: Maybe<Stack>;
  status?: Maybe<AppStatus>;
  domains?: Maybe<Array<Maybe<CreateDomainInput>>>;
  image?: Maybe<Scalars['Upload']>;
  businessEntityName?: Maybe<Scalars['String']>;
  termsOfServiceURL?: Maybe<Scalars['String']>;
  privacyPolicyURL?: Maybe<Scalars['String']>;
  preventDisposableEmails?: Maybe<Scalars['Boolean']>;
  ssoSignupButtonURL?: Maybe<Scalars['String']>;
  stripeIdealEnabled?: Maybe<Scalars['Boolean']>;
  stripeTaxIdCollectionEnabled?: Maybe<Scalars['Boolean']>;
  stripeBillingAddressCollectionEnabled?: Maybe<Scalars['Boolean']>;
  stripePortalCancelImmediately?: Maybe<Scalars['Boolean']>;
  stripeUseCustomerPortalSettings?: Maybe<Scalars['Boolean']>;
  stripeDisablePaymentModeInvoices?: Maybe<Scalars['Boolean']>;
  stripePortalDisableCancel?: Maybe<Scalars['Boolean']>;
  captchaEnabled?: Maybe<Scalars['Boolean']>;
  requireUser2FA?: Maybe<Scalars['Boolean']>;
  autoVerifySocialEmails?: Maybe<Scalars['Boolean']>;
  clientMessageTextEnabled?: Maybe<Scalars['Boolean']>;
  disableConcurrentLogins?: Maybe<Scalars['Boolean']>;
  memberSessionDurationDays?: Maybe<Scalars['Int']>;
  weeklyRoundupEmailEnabled?: Maybe<Scalars['Boolean']>;
  dailyCommentingEmailEnabled?: Maybe<Scalars['Boolean']>;
  sendWelcomeEmailAfterVerification?: Maybe<Scalars['Boolean']>;
};

export type UpdateAppPayload = {
  __typename?: 'UpdateAppPayload';
  id: Scalars['ID'];
  name: Scalars['String'];
  domains?: Maybe<Array<Maybe<Domain>>>;
  status: AppStatus;
  createdAt: Scalars['String'];
};

export type UpdateBrandingInput = {
  colors?: Maybe<UpdateColorsInput>;
  style?: Maybe<UpdateStyleInput>;
  text?: Maybe<UpdateTextInput>;
  logo?: Maybe<Scalars['String']>;
  appearance?: Maybe<Appearance>;
};

export type UpdateButtonComponentStylesInput = {
  corners?: Maybe<Corners>;
  borders?: Maybe<Borders>;
  shadows?: Maybe<Shadows>;
};

export type UpdateChannelInput = {
  channelId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateClientMessageTextInput = {
  textObject?: Maybe<Scalars['JSON']>;
};

export type UpdateColorsInput = {
  light?: Maybe<UpdateLightDarkColorsInput>;
  dark?: Maybe<UpdateLightDarkColorsInput>;
  appearance?: Maybe<Appearance>;
};

export type UpdateCustomContentInput = {
  customContentId: Scalars['ID'];
  content: Scalars['String'];
  name: Scalars['String'];
  type: CustomContentType;
};

export type UpdateCustomDomainInput = {
  rootDomain?: Maybe<Scalars['String']>;
  authSubdomain?: Maybe<Scalars['String']>;
  clientSubdomain?: Maybe<Scalars['String']>;
  staticSubdomain?: Maybe<Scalars['String']>;
};

export type UpdateCustomFieldInput = {
  customFieldId: Scalars['ID'];
  label: Scalars['String'];
  hidden?: Maybe<Scalars['Boolean']>;
  tableHidden?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<CustomFieldVisibility>;
};

export type UpdateDomainInput = {
  id: Scalars['ID'];
  domain: Scalars['String'];
  mode: DomainMode;
};

export type UpdateEmailsInput = {
  passwordReset?: Maybe<PasswordResetTemplateInput>;
  emailVerification?: Maybe<EmailVerificationTemplateInput>;
  passwordlessLogin?: Maybe<PasswordlessLoginTemplateInput>;
  commentNotification?: Maybe<CommentNotificationTemplateInput>;
  welcome?: Maybe<WelcomeTemplateInput>;
  abandonedCart?: Maybe<AbandonedCartTemplateInput>;
  emailVerificationEnabled?: Maybe<Scalars['Boolean']>;
  requireEmailVerification?: Maybe<Scalars['Boolean']>;
  welcomeEmailsEnabled?: Maybe<Scalars['Boolean']>;
  abandonedCartEmailsEnabled?: Maybe<Scalars['Boolean']>;
  abandonedCartExpireMinutes?: Maybe<Scalars['Int']>;
};

export type UpdateGlobalCommentSettingsInput = {
  moderateAllComments?: Maybe<Scalars['Boolean']>;
  moderateNewMembers?: Maybe<Scalars['Boolean']>;
  useDefaultRestrictedWords?: Maybe<Scalars['Boolean']>;
  restrictedWords?: Maybe<Scalars['String']>;
  aiModerationEnabled?: Maybe<Scalars['Boolean']>;
  communityDescription?: Maybe<Scalars['String']>;
  aiSpamThreshold?: Maybe<Scalars['Int']>;
};

export type UpdateLightDarkColorsInput = {
  primaryButton?: Maybe<Scalars['String']>;
  secondaryButton?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  errorBackground?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
  titles?: Maybe<Scalars['String']>;
  subtitles?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  shadowColor?: Maybe<Scalars['String']>;
};

export type UpdateMemberAuthInput = {
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['JSON']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type UpdateMemberInput = {
  metaData?: Maybe<Scalars['JSON']>;
  customFields?: Maybe<Scalars['JSON']>;
  memberId?: Maybe<Scalars['ID']>;
  json?: Maybe<Scalars['JSON']>;
  loginRedirect?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  isModerator?: Maybe<Scalars['Boolean']>;
  trustStatus?: Maybe<MemberTrustStatus>;
};

export type UpdateMemberNoteInput = {
  memberId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type UpdatePasswordlessSettingsInput = {
  enabled: Scalars['Boolean'];
  defaultToPasswordless: Scalars['Boolean'];
};

export type UpdatePermissionGroupInput = {
  name?: Maybe<Scalars['String']>;
  permissionGroupId: Scalars['ID'];
};

export type UpdatePermissionInput = {
  permissionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdatePlanInput = {
  planId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatus>;
  metaData?: Maybe<Scalars['JSON']>;
  permissionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  image?: Maybe<Scalars['Upload']>;
  redirects?: Maybe<RedirectsInput>;
  allowedDomains?: Maybe<Array<Maybe<Scalars['EmailAddress']>>>;
  limitMembers?: Maybe<Scalars['Boolean']>;
  memberLimit?: Maybe<Scalars['Int']>;
  teamAccountUpgradeLink?: Maybe<Scalars['String']>;
  teamAccountInviteSignupLink?: Maybe<Scalars['String']>;
  restrictToAdmin?: Maybe<Scalars['Boolean']>;
};

export type UpdatePlanLogicInput = {
  planId: Scalars['ID'];
  addedLogic?: Maybe<PlanLogicSingleInput>;
  removedLogic?: Maybe<PlanLogicSingleInput>;
};

export type UpdatePostInput = {
  postId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  status?: Maybe<PostStatus>;
};

export type UpdatePriceInput = {
  priceId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PriceType>;
  description?: Maybe<Scalars['String']>;
  currency?: Maybe<PriceCurrency>;
  intervalType?: Maybe<PriceIntervalType>;
  intervalCount?: Maybe<Scalars['Int']>;
  setupFeeAmount?: Maybe<Scalars['Float']>;
  setupFeeName?: Maybe<Scalars['String']>;
  setupFeeEnabled?: Maybe<Scalars['Boolean']>;
  freeTrialEnabled?: Maybe<Scalars['Boolean']>;
  freeTrialRequiresCard?: Maybe<Scalars['Boolean']>;
  freeTrialDays?: Maybe<Scalars['Int']>;
  expirationCount?: Maybe<Scalars['Int']>;
  expirationInterval?: Maybe<PriceExpirationInterval>;
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  taxType?: Maybe<TaxType>;
  maxTeamMembers?: Maybe<Scalars['Int']>;
  teamAccountsEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateRestrictedUrlGroupInput = {
  restrictedUrlGroupId: Scalars['ID'];
  name: Scalars['String'];
  redirect: Scalars['String'];
  allowAllMembers: Scalars['Boolean'];
  key: Scalars['String'];
};

export type UpdateRestrictedUrlInput = {
  restrictedUrlId: Scalars['ID'];
  url: Scalars['String'];
  filter?: Maybe<FilterType>;
};

export type UpdateSsoClientInput = {
  provider: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  discoveryUrl?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  issuer?: Maybe<Scalars['String']>;
  authorizationUrl?: Maybe<Scalars['String']>;
  tokenUrl?: Maybe<Scalars['String']>;
  userInfoUrl?: Maybe<Scalars['String']>;
};

export type UpdateSsoTextInput = {
  textObject?: Maybe<Scalars['JSON']>;
};

export type UpdateStyleInput = {
  fontSize?: Maybe<FontSize>;
  components?: Maybe<UpdateButtonComponentStylesInput>;
  buttons?: Maybe<UpdateButtonComponentStylesInput>;
};

export type UpdateTextInput = {
  titleLogin?: Maybe<Scalars['String']>;
  subtitleLogin?: Maybe<Scalars['String']>;
  titleSignup?: Maybe<Scalars['String']>;
  subtitleSignupFree?: Maybe<Scalars['String']>;
  subtitleSignupPaid?: Maybe<Scalars['String']>;
  buttonLogin?: Maybe<Scalars['String']>;
  buttonSignup?: Maybe<Scalars['String']>;
};

export type UpdateUserAuthInput = {
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateWebhookInput = {
  id: Scalars['ID'];
  events?: Maybe<Array<Maybe<Scalars['Event']>>>;
  apiVersion?: Maybe<Scalars['APIVersion']>;
  enabled?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  auth: UserAuth;
  profile: UserProfile;
  appConnections?: Maybe<Array<Maybe<UserAppConnection>>>;
  session?: Maybe<UserSession>;
};

export type UserAppConnection = {
  __typename?: 'UserAppConnection';
  app: App;
  role: UserAppRole;
};

export enum UserAppRole {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  MembersWrite = 'MEMBERS_WRITE',
  MembersRead = 'MEMBERS_READ'
}

export type UserAuth = {
  __typename?: 'UserAuth';
  email: Scalars['String'];
  connectedToGoogle: Scalars['Boolean'];
  twoFactorSettings?: Maybe<UserTwoFactorSettings>;
  commandBarHMAC?: Maybe<Scalars['String']>;
};

export type UserAuthPayload = {
  __typename?: 'UserAuthPayload';
  user: User;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: AppUserConnection;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserResetPasswordEmailInput = {
  email: Scalars['String'];
};

export type UserResetPasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UserSession = {
  __typename?: 'UserSession';
  acr: UserSessionAcr;
  connectedToWebflow: Scalars['Boolean'];
  role?: Maybe<UserAppRole>;
};

export enum UserSessionAcr {
  Password = 'PASSWORD',
  Twofactor = 'TWOFACTOR'
}

export type UserTwoFactorAuthPayload = {
  __typename?: 'UserTwoFactorAuthPayload';
  secret: Scalars['String'];
  url: Scalars['String'];
};

export type UserTwoFactorSettings = {
  __typename?: 'UserTwoFactorSettings';
  enabled: Scalars['Boolean'];
  validated: Scalars['Boolean'];
  backupCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VerifyDomainInput = {
  domain: Scalars['String'];
};

export type WebflowDomain = {
  __typename?: 'WebflowDomain';
  domain: Scalars['String'];
};

export type WebflowSite = {
  __typename?: 'WebflowSite';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
};

export type Webhook = {
  __typename?: 'Webhook';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  events?: Maybe<Array<Maybe<Scalars['Event']>>>;
  apiVersion: Scalars['APIVersion'];
  endpoint: Scalars['String'];
  signingSecret: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AbandonedCartTemplate = {
  __typename?: 'abandonedCartTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type AbandonedCartTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type CommentNotificationTemplate = {
  __typename?: 'commentNotificationTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
  unsubscribeLinkText?: Maybe<Scalars['String']>;
};

export type CommentNotificationTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
  unsubscribeLinkText?: Maybe<Scalars['String']>;
};

export type EmailDnsSettings = {
  __typename?: 'emailDNSSettings';
  DKIMHost?: Maybe<Scalars['String']>;
  DKIMTextValue?: Maybe<Scalars['String']>;
  ReturnPathCNAMEValue?: Maybe<Scalars['String']>;
  ReturnPathDomain?: Maybe<Scalars['String']>;
};

export type EmailVerificationTemplate = {
  __typename?: 'emailVerificationTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type EmailVerificationTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type GenerateMemberPasswordInput = {
  memberId?: Maybe<Scalars['ID']>;
};

export type PasswordResetTemplate = {
  __typename?: 'passwordResetTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
};

export type PasswordResetTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
};

export type PasswordlessLoginTemplate = {
  __typename?: 'passwordlessLoginTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type PasswordlessLoginTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type WelcomeTemplate = {
  __typename?: 'welcomeTemplate';
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};

export type WelcomeTemplateInput = {
  replyTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  paragraph1?: Maybe<Scalars['String']>;
  paragraph2?: Maybe<Scalars['String']>;
  paragraph3?: Maybe<Scalars['String']>;
  primaryButtonText?: Maybe<Scalars['String']>;
};
