import React, { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useGenerateZendeskTokenMutation } from "features";
import { useToastAlertContext } from "contexts/ToastAlertContext";

const RootLayout = () => {
  const location = useLocation();
  const [generateZendeskToken] = useGenerateZendeskTokenMutation();
  const { createToastAlert } = useToastAlertContext();
  const [searchParams] = useSearchParams();

  const message = searchParams.get("message");

  useEffect(() => {
    if (message) {
      createToastAlert({ message, alertType: "error" });
    }
  }, [message, createToastAlert]);

  useEffect(() => {
    const hadBrandId = Boolean(searchParams.get("brand_id"));
    const isLogInPath = location.pathname === "/login";
    if (hadBrandId && isLogInPath) {
      const generateToken = async () => {
        const {
          data: { generateZendeskToken: token },
        } = await generateZendeskToken();
        let zendeskUrl = `https://memberstack-v2.zendesk.com/access/jwt?jwt=${token}`;
        const params = new URLSearchParams(window.location.search);
        const returnTo = params.get("return_to");
        if (returnTo) zendeskUrl += `&return_to=${returnTo}`;
        window.location.replace(zendeskUrl);
      };
      // eslint-disable-next-line no-console
      generateToken().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, searchParams]);

  useEffect(() => {
    // changes URL from /apps/app_123/members to members, etc.
    let page = location.pathname.split("/").pop() as string;
    if (!page || page === "apps") return;

    if (page.includes("pln_")) page = "plans";
    if (page.includes("prc_")) page = "prices";

    if (location.pathname.includes("community")) page = "community";

    try {
      window.revgems("trackPageView", {
        occurredAt: new Date(),
        title: document.title,
        referrer: document.referrer,
        url: `${window.location.origin}/${page}`,
      });
    } catch (err) {
      console.log(err);
    }
  }, [location]);

  return <Outlet />;
};

export default RootLayout;
